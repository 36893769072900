import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting started`}</h1>
    <p>{`At Tickitto, we are on a mission to make the world's best ticketed experiences more accessible.`}</p>
    <p>{`We are building infrastructure for open ticketing and believe that with a few lines of code, any developer should be able to access a diverse ticket supply.`}</p>
    <p>{`We are committed to providing a great developer experience and having up to date, accurate documentation is of the utmost importance to us.`}</p>
    <p>{`Any changes made to the Tickitto API will be posted on our change-log.`}</p>
    <p>{`The API docs are primarily intended for technical audiences but may be helpful for any Partner working with Tickitto.`}</p>
    <p>{`If you have any questions or feedback, please email us on `}<a parentName="p" {...{
        "href": "mailto:hello@tickitto.com"
      }}>{`hello@tickitto.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      